const PATH = {
  HOME: '/',
  SIGNUP: '/auth/signup',

  ADMIN: {
    HOME: '/admin',
    DASHBOARD: '/admin/dashboard',
    CONTROLBOX: '/admin/controlbox',

    SITE_SETTINGS: '/admin/site/settings',
    SITE_USER_AUTH: '/admin/site/user-auth',
    SITE_WELCOME_MAIL: '/admin/site/welcome-mail',

    LIVE_SURVEY: '/admin/live-survey',
    SCHEDULE: '/admin/schedule',

    DIRECTOR: '/admin/live/director',
    CHAT: '/admin/live/chat',
    LIVE: '/admin/live/broadcast',
    LOG_REPORT: '/admin/live/log-report',
    VOD: '/admin/vod',
    CHANNEL: '/admin/live/channel',
    WELCOME: '/admin/welcome',

    EXAM_LIST: '/admin/exam/list',
    EXAM_ADD: '/admin/exam/add',
    EXAM_EDIT: '/admin/exam/edit',
    EXAM_REPORT: '/admin/exam/report',

    SURVEY_LIST: '/admin/survey/list',
    SURVEY_ADD: '/admin/survey/add',
    SURVEY_EDIT: '/admin/survey/edit',
    // SURVEY_ITEM: '/admin/make-survey',
    SURVEY_REPORT: '/admin/survey/report',

    MENU: '/admin/site/menu',
    MENU_CHILD: '/admin/site/menu/child',

    USERS: '/admin/users/list',
    PROFILE: '/admin/users/profile',
    TICKET: '/admin/users/ticket',
    AGREEMENTS: '/admin/users/agreements',

    ABOUT: '/admin/page/about',
    SPONSOR: '/admin/page/sponsor',
    SPEAKER: '/admin/page/speaker',
    SEGMENTS: '/admin/page/segments/:pageName?',
    GUIDE: '/admin/page/segments/guide',
    GRADE_GUIDE: '/admin/page/segments/grade-guide',
    REGISTER_INFO: '/admin/page/segments/register-info',
    PAGE: '/admin/page/static',

    UTIL_EMAIL_VALIDATOR: '/admin/utils/email-validator',

    BROADCAST: '/admin/broadcast',
    CATEGORY: '/admin/category',
    STREAMING: '/admin/streaming',
  },
  POPUP: {
    QNA: '/popup/admin/qna',
  },

  AUTH: {
    LOGIN: '/auth/login',
    REGISTER: '/auth/register',
    REGISTER_SHORT: '/r',
    FORGOT_PASSWORD: '/auth/forgot-password',
    REGISTER_COMPLETE: '/register/complete',
    LOGOUT: '/auth/logout',
  },

  SYSTEM_404: '/auth/404',
}

export default PATH
